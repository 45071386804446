import {TrackingModel} from "shared/models";
import { httpService } from "./http-service"

class TrackingService {
    public getTracking = async (trackingId: string):Promise<TrackingModel> => {
        const result = await httpService.get(`/api/Tracking/${trackingId}`, {})
        
        return result.data
    }
}

export const trackingService = new TrackingService()